.header {
  top: 0%;
  background-color: #3366cc;
  height: 40px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.header__brand {
  .logo_brand {
    margin-left: 45px;
  }
}
.header__link {
  color: white;
  cursor: pointer;
}
.marcapais{
  vertical-align: top;
  margin-left: 15px;
}
.a_header {
  color: white;
}

.btn_login {
  position: absolute;
  background: white;
  border-radius: 30px;
  color: black;
  padding: 0.4% 1%;
  border: none;
  height: 30px;
  text-transform: uppercase;
  right: 12%;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:active,
  &:focus {
    outline: none;
  }
}

@media only screen and (max-width: 500px) {
  .btn_login {
    right: 35%;
  }
  .header{
    display: flex;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1080px) {
  .btn_login {
    right: 25%;
  }
}
