.footer {
  border-top: 1px solid #dcdcdc;
  width: 100%;
  background: #3366CC;
  margin: 0 auto;
  padding-top: 50px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 26px;
  // Grid layout
  display: flex;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 0.2fr;
  gap: 0px 112px;
  position: relative;
  justify-content: space-around;
  
}

.footer__info {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  
}


.footer__title {
  color:white;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 18px;
}

.footer__label {
  font-size: 12px;
  color: white;
  margin-bottom: 6px;
  font-weight: 500;
}

.redes_texto{
  justify-content: center;
  margin-left: -7px;
}
.bg_logo{
  // background: #81ABFF;
  width: 50.91px;
  height: 50.91px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}
.logos_redes{
  position: relative;
  vertical-align: middle;
}
.links{
  display: block;
  
}
.logos_gov{
  display: grid;
  justify-items: center;
}

.logopais{
  margin-top: 20px;
}
.vl {
  border-left: 1px solid white;
  height: auto;
}
.footer__value {
  color: white;
  font-size: 12px;
  margin-bottom: 18px;
  
  font-weight: normal;
  &.link {
    color: white;
    cursor: pointer;
    text-decoration: underline;
    word-break: break-all;
  }
  &.no-margin-bottom {
    margin-bottom: 6px;
    align-self: center;
  }
}

// estilos para celulares
@media only screen and (max-width: 500px) {
  .logos_gov {
    
    margin-bottom: 20px;
  }
  .vl {
    display: none;
  }
  
  .footer {
    display: block;
  }
  
}

// estilos para tablet
@media only screen and (min-width: 500px) and (max-width: 1080px) {
  .footer {
    gap: 0px 10px;
    margin-bottom: 50px;
    grid-template-columns: 1fr 1fr 1fr;
    
  }
  .vl {
    display: none;
  }
 
}